<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>
      <div
        class="mx-auto max-w-xl flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3"
      >
        <div class="p-4 mb-3 space-y-3">
          <div>
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>
        </div>
        <div
          class="mt-2 border-transparent rounded-xl border-2 bg-gray-900 bg-opacity-50"
        >
          <div class="flex flex-row px-8 py-3 sm:py-6">
            <div class="flex flex-grow items-center py-0">
              <span
                class="text-3xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-pink-400"
                >Exchange
              </span>
            </div>
            <div class="flex-none mt-2">Trade tokens in an instant</div>
          </div>
          <div class="px-8 py-3 sm:py-6">
            <div v-if="getBalance && getCurrency && getMarket">
              <div class="flex flex-col">
                <div>
                  <div class="flex font-semibold mt-2">
                    <span
                      class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                    >
                      Balance </span
                    ><span
                      class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base"
                      ><span v-if="getUser"
                        >{{ fixed(getBalance[token0].available) }}
                        {{ token0 }}</span
                      ></span
                    >
                  </div>
                  <div class="mt-2">
                    <div
                      class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                    >
                      <input
                        id="amount"
                        v-model="amount0"
                        placeholder="0"
                        class="flex-grow w-full outline-none text-lg bg-transparent"
                        type="number"
                        min="0"
                        step="0.00000001"
                      /><span
                        v-if="getUser"
                        class="w-24 flex-none text-sm px-6 mt-1 rounded text-gray-400 cursor-pointer hover:text-yellow-200"
                        v-on:click="amount0 = getBalance[token0].available"
                      >
                        MAX
                      </span>
                      <img
                        class="h-5 mr-2 mt-1"
                        :src="getCurrency[token0].logo"
                      />
                      <span class="text-lg">
                        {{ getCurrency[token0].coin }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center py-2" v-on:click="modeswap()">
                <a class="items-center text-center cursor-pointer w-full"
                  ><i class="fad fa-sort-alt hover:text-yellow-200"></i
                ></a>
              </div>
              <div class="grid grid-cols-1 gap-y-3">
                <div class="flex flex-col space-y-8 space-y-0">
                  <div>
                    <div class="flex font-semibold mt-2">
                      <span
                        class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                      >
                        Balance </span
                      ><span
                        class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base"
                        ><span v-if="getUser"
                          >{{ fixed(getBalance[token1].available) }}
                          {{ token1 }}</span
                        ></span
                      >
                    </div>
                    <div class="mt-2">
                      <div
                        class="flex justify-center rounded-xl bg-gray-700 py-1 sm:py-3 px-3"
                      >
                        <span
                          class="flex-grow w-full outline-none text-lg bg-transparent"
                          >{{ fixed(amount1) }}</span
                        >
                        <img
                          class="h-5 mr-2 mt-1"
                          :src="getCurrency[token1].logo"
                        />
                        <span class="text-lg">
                          {{ getCurrency[token1].coin }}</span
                        >
                      </div>
                    </div>
                    <div class="flex justify-center py-3">
                      <button
                        v-if="loading"
                        class="w-full flex-none text-sm px-6 py-2 border rounded-xl border-none text-gray-400 bg-gray-700 cursor-wait"
                      >
                        <i class="fad fa-spinner-third fa-spin m-r-xs"></i>
                        Loading
                      </button>
                      <button
                        v-else-if="!amount0"
                        class="w-full flex-none text-sm px-6 py-2 border rounded-xl border-none text-gray-400 bg-gray-700 cursor-not-allowed"
                        disabled
                      >
                        Enter an amount
                      </button>
                      <button
                        v-else-if="mode"
                        v-on:click="swapbuy()"
                        class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-pink-600 to-yellow-600"
                      >
                        Swap
                      </button>
                      <button
                        v-else
                        v-on:click="swapsell()"
                        class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-pink-600 to-yellow-600"
                      >
                        Swap
                      </button>
                    </div>
                    <div class="text-gray-500 mt-2 text-xs sm:text-base">
                      1 {{ token1 }} =
                      {{ fixed(price) }}
                      {{ token0 }}
                    </div>
                    <span
                      v-if="priceimpact && amount0 > 0"
                      class="grid grid-cols-2 gap-0 sm:flex-row p-2 border rounded-lg mb-2 border-opacity-30 text-xs sm:text-base"
                    >
                      <span>Average Price</span>
                      <span class="text-right"
                        >{{ fixed(average) }} {{ token0 }} per
                        {{ token1 }}
                      </span>
                      <span>Price Impact</span>
                      <span class="text-right"
                        >{{ priceimpact.toFixed(2) }}%
                      </span>
                      <span>Liquidity Provider Fee</span
                      ><span class="text-right">
                        {{ fixed((amount1 * 0.25) / 100) }}
                        {{ token1 }}
                      </span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LiveTx />
      <footer>
        <StickFooter />
      </footer>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import LiveTx from "@/components/LiveTx.vue";
import { mapGetters, mapActions } from "vuex";
import liquidity from "@/api/liquidity";
import nativeToast from "native-toast";
import func from "@/widgets/func";

export default {
  data() {
    return {
      mode: 1, // 1 = buy
      price: null,
      priceimpact: null,
      average: null,
      amount0: null,
      amount1: 0,
      token0: null,
      token1: null,
      loading: false,
      liquidity: null,
      symbol: null,
    };
  },
  components: {
    LiveTx,
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters(["getBalance", "getCurrency", "getMarket", "getUser"]),

  methods: {
    fixed(a) {
      return func.fixed(a);
    },
    modeswap() {
      this.mode = !this.mode;
      [this.token0, this.token1] = [this.token1, this.token0];
    },
    async swapbuy() {
      this.loading = true;

      if (this.getBalance[this.token0].available < this.amount0) {
        this.loading = false;
        nativeToast({
          message: `The amount you entered is more than your available balance.( You have ${
            this.getBalance[this.token0].available
          } ${this.token0})`,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      } else {
        await liquidity
          .swapbuy({
            amount0: this.amount0,
            symbol: this.symbol,
          })
          .then((response) => {
            this.loading = false;
            nativeToast({
              message: response.data.message,
              position: "top",
              timeout: 10000,
              type: "success",
            });
            this.amount0 = null;
          })
          .catch((error) => {
            this.loading = false;
            nativeToast({
              message: error.response.data.error,
              position: "top",
              timeout: 3000,
              type: "error",
            });
          });
      }
    },
    async swapsell() {
      this.loading = true;
      if (this.getBalance[this.token0].available < this.amount0) {
        this.loading = false;
        nativeToast({
          message: `The amount you entered is more than your available balance.( You have ${
            this.getBalance[this.token0].available
          } ${this.token0})`,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      } else {
        await liquidity
          .swapsell({
            amount1: this.amount0,
            symbol: this.symbol,
          })
          .then((response) => {
            this.loading = false;
            nativeToast({
              message: response.data.message,
              position: "top",
              timeout: 10000,
              type: "success",
            });
            this.amount0 = null;
          })
          .catch((error) => {
            this.loading = false;
            nativeToast({
              message: error.response.data.error,
              position: "top",
              timeout: 3000,
              type: "error",
            });
          });
      }
    },
  },
  async created() {
    this.symbol = this.$route.params.symbol;
    this.token0 = this.$route.params.symbol.split("_")[0];
    this.token1 = this.$route.params.symbol.split("_")[1];
    this.price =
      this.getMarket[this.symbol].reserve0 /
      this.getMarket[this.symbol].reserve1;
  },
  updated() {
    if (this.mode) {
      this.price =
        this.getMarket[this.symbol].reserve0 /
        this.getMarket[this.symbol].reserve1;
      var nextX = this.getMarket[this.symbol].reserve0 + this.amount0 / 1;
      var nextY = this.getMarket[this.symbol].kLast / nextX;
      this.amount1 = this.getMarket[this.symbol].reserve1 - nextY;
      this.average = this.amount0 / this.amount1;
      this.priceimpact = ((this.price - this.average) * 100) / this.average;
    } else {
      this.price =
        this.getMarket[this.symbol].reserve1 /
        this.getMarket[this.symbol].reserve0;
      var nextY = this.getMarket[this.symbol].reserve1 + this.amount0 / 1;
      var nextX = this.getMarket[this.symbol].kLast / nextY;
      this.amount1 = this.getMarket[this.symbol].reserve0 - nextX;
      this.average = this.amount0 / this.amount1;
      this.priceimpact = ((this.price - this.average) * 100) / this.average;
    }
  },
};
</script>
